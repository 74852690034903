<template>
  <div id="chart">
    <v-row>
      <v-col cols="6">
        <apexchart type="donut" :options="chartOptions1" :series="series1"></apexchart>
      </v-col>
      <v-col cols="6">
        <apexchart type="donut" :options="chartOptions2" :series="series2"></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <apexchart type="donut" :options="chartOptions3" :series="series3"></apexchart>
      </v-col>
      <v-col cols="6">
        <apexchart type="donut" :options="chartOptions4" :series="series4"></apexchart>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
  import VueApexCharts from 'vue-apexcharts'
  
  export default {
    components: {
      apexchart: VueApexCharts
    },
    props: {
        idAno: Number,
        idUnidade: Number
    },
    data() {
      return {
        series1: [],
        series2: [],
        series3: [],
        series4: [],
        
        chartOptions1: {
          chart: {
            type: 'donut',
            width: '100%',  // Define a largura do gráfico
          },
          labels: ['Avaliação Registrada', 'Avaliação Não Registrada'],
          colors: ['#00E396', '#FF4560'],
          title: {
            text: '1º Bimestre'
          },
          responsive: [{
              breakpoint: 480,
              options: {
                  chart: {
                      width: 200
                  },
                  legend: {
                      position: 'bottom'
                  }
              }
          }],
        },
        chartOptions2: {
          chart: {
            type: 'donut'
          },
          labels: ['Avaliação Registrada', 'Avaliação Não Registrada'],
          colors: ['#00E396', '#FF4560'],
          title: {
            text: '2º Bimestre'
          },
          responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
        },
        chartOptions3: {
          chart: {
            type: 'donut'
          },
          labels: ['Avaliação Registrada', 'Avaliação Não Registrada'],
          colors: ['#00E396', '#FF4560'],
          title: {
            text: '3º Bimestre'
          },
          responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
        },
        chartOptions4: {
          chart: {
            type: 'donut'
          },
          labels: ['Avaliação Registrada', 'Avaliação Não Registrada'],
          colors: ['#00E396', '#FF4560'],
          title: {
            text: '4º Bimestre'
          },
          responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
        }
      }
    },
    methods: {
        getBimestre1() {
            this.axios.get('avaliacao/acompanhamentoavaliacao', {
                params: {
                    intUnidade: this.$props.idUnidade,
                    intAnoLetivo: this.$props.idAno,
                    intTipoSegmento: 4,
                    strDivisao: '%1%',
                    intTipoRel: 1
                }
            }).then(res => {
              this.series1.push(res.data[0].Sim, (res.data[0].Total - res.data[0].Sim))
            })
        },
    getBimestre2() {
            this.axios.get('avaliacao/acompanhamentoavaliacao', {
                params: {
                    intUnidade: this.$props.idUnidade,
                    intAnoLetivo: this.$props.idAno,
                    intTipoSegmento: 4,
                    strDivisao: '%2%',
                    intTipoRel: 1
                }
            }).then(res => {
              this.series2.push(res.data[0].Sim, (res.data[0].Total - res.data[0].Sim))
            })
        },
    getBimestre3() {
            this.axios.get('avaliacao/acompanhamentoavaliacao', {
                params: {
                    intUnidade: this.$props.idUnidade,
                    intAnoLetivo: this.$props.idAno,
                    intTipoSegmento: 4,
                    strDivisao: '%3%',
                    intTipoRel: 1
                }
            }).then(res => {
              this.series3.push(res.data[0].Sim, (res.data[0].Total - res.data[0].Sim))
            })
        },
    getBimestre4() {
      
            this.axios.get('avaliacao/acompanhamentoavaliacao', {
                params: {
                    intUnidade: this.$props.idUnidade,
                    intAnoLetivo: this.$props.idAno,
                    intTipoSegmento: 4,
                    strDivisao: '%4%',
                    intTipoRel: 1
                }
            }).then(res => {
                this.series4.push(res.data[0].Sim, (res.data[0].Total - res.data[0].Sim))
              })
        },
      },
    mounted() {
      this.getBimestre1()
      this.getBimestre2()
      this.getBimestre3()
      this.getBimestre4()
    }
  }
  </script>
  
  <style>
  .apexcharts-canvas {
    max-width: 100% !important;
    height: auto !important;
  }
  </style>
  